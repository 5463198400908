<template>
  <v-card>
    <v-alert
      :value="exportDisable"
      colored-border
      dismissible
      color="primary lighten-1"
      border="top"
      icon="mdi-check"
      transition="scale-transition"
      elevation="2"
      prominent
    >
      🤾🏻‍♀️ 🤸🏻‍♂️ 🏌🏻‍♀️ 🤾🏻‍♂️ Your export is currently processing. Once the export is completed 🏁, you will receive a notification via Slack under
      <code>#admindashboard</code> channel 📢.
    </v-alert>
    <v-card-title>Mobile User Management</v-card-title>
    <v-card-title>
      <v-btn @click="exportAllUsers()" class="ml-5" color="primary">Export all Users</v-btn>
      <v-btn @click="exportActiveUsers(1)" class="ml-5" color="primary">Active Users (last month)</v-btn>
      <v-btn @click="exportActiveUsers(6)" class="ml-5" color="primary">Active Users (last 6 months)</v-btn>
      <v-btn @click="$router.push({ name: 'users' })" class="ml-5" color="primary">User Count</v-btn>
      <v-btn @click="$router.push({ name: 'UserFilter' })" class="ml-5" color="primary">Search User</v-btn>
      <v-spacer></v-spacer>

      <v-text-field
        v-model="searchCriteria.text"
        label="Enter Email Id"
        single-line
        hide-details
        v-validate="'required'"
        data-vv-name="email id"
        :error-messages="errors.collect('email id')"
      ></v-text-field>
      <v-btn color="primary" @click="searchRespondent">Search</v-btn>
    </v-card-title>

    <div class="mb-5 ml-3">
      <v-btn @click="$router.push({ name: 'ActiveUsers' })" class="ml-5" color="primary">Active Users (Data Sharing)</v-btn>
    </div>

    <div v-if="totalDocs > 0" class="pl-4">
      <p>Total Users: {{ totalDocs}}</p>
    </div>

    <v-data-table :headers="headers" :items="users">
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="$router.push({ path: `/mobile/user/${item._id}/profile` })"
        >edit</v-icon>
      </template>
    </v-data-table>

    <div class="text-xs-center pt-2">
      <v-pagination
        color="primary"
        v-model="pagination.page"
        :length="Math.ceil(totalDocs/10)"
        :total-visible="7"
        @input="(pageNumber) => loadUsers(pageNumber)"
      ></v-pagination>
    </div>
  </v-card>
</template>

<script>
import RestResource from "@/services/dataServiceMobile.js";

const service = new RestResource();

export default {
  data() {
    return {
      search: "",
      exportDisable: false,
      searchCriteria: {},
      pagination: {},
      headers: [
        { text: "umUserId", value: "_id", sortable: true },
        { text: "userId", value: "userId", sortable: true },
        { text: "Name", value: "name", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "Country Code", value: "countryCode", sortable: true },
        { text: "Mobile Number", value: "mobileNumber", sortable: false },
        { text: "Actions", value: "actions", sortable: false }
      ],
      users: [],
      totalDocs: 0,
      nextPage: 1,
      userList: {}
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.nextPage = 1;
      this.loadUsers();
    },

    loadUsers(pageNumber) {
      this.userList.page = pageNumber ? pageNumber : this.nextPage;

      this.$setLoader();
      service.getMobileUsers(this.userList).then(r => {
        this.users = r.data.concatUsers;
        this.nextPage = r.data.nextPage;
        this.totalDocs = r.data.totalDocs;
        this.$disableLoader();
      });
    },

    exportAllUsers() {
      service.exportAllUsers();
      this.exportDisable = true;
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },

    searchRespondent() {
      this.$setLoader();
      this.users = [];

      service.findUserByText(this.searchCriteria).then(r => {
        this.users = r.data.data;
        this.$disableLoader();
      });
    },

    exportActiveUsers(timePeriod) {
      service.exportActiveUsers({ exportDuration: timePeriod })
      this.exportDisable = true;
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }

  }
};
</script>